import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {DiscountGroupInTree} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {createFetchDiscountGroupRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class RemoveDiscountGroup extends React.PureComponent {
    static propTypes = {
        group: DiscountGroupInTree,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {group} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie rabatovej skupiny</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reloadData}
            title={<Trans>Odstránenie rabatovej skupiny</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť rabatovú skupinu?</Trans>,
                createFetchDiscountGroupRemove(group.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť rabatovú skupinu.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveDiscountGroup);